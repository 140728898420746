.formula-detail-toggle-row {
    align-items: center;
    display: flex;
    margin-bottom: 1em;
}

.formula-detail-toggle-element {
    color: darkgray;
    overflow: hidden;
    width: 12em;
    transition: all 0.5s ease-out;
    background: rgba(0, 0, 0, 0);
    padding: 0.5em 1em;
}

.modal-dialog {
    max-width: 80% !important;
}

.modal-content,
.modal-card {
    width: 80% !important;
}

@media screen and (min-width: 0px) and (max-width: 1000px) {
    .modal-dialog {
        max-width: 100% !important;
    }

    .modal-content,
    .modal-card {
        width: 85% !important;
    }
}

.formula-wrapper {
    padding: 0.6em;
    white-space: pre;
    margin-bottom: 1em;
}

.formula-title {
    min-width: 10em;
}

.formula-value {
    font-weight: bold;
    font-family: math;
}

.formula-subtitle {
    margin-top: 0.5em;
    font-size: 0.7em;
}
