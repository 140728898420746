.boxed-nav-item {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin: 1em !important;
    background: white;
    font-weight: bold !important;
    opacity: 1 !important;
    border-radius: 0.5em !important;
    color: var(--eon-red) !important;
    transition: background 0.2s ease-out;
}

.boxed-nav-item:hover {
    color: white !important;
    background: lightgray !important;
}

.boxed-nav-item-active {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    margin: 1em !important;
    background: white;
    font-weight: bold !important;
    opacity: 1 !important;
    border-radius: 0.5em !important;
    color: white !important;
    background: var(--eon-blue) !important;
}

.menu-icon {}

.nav-element {
    padding: 10px 15px;
    height: 5em;
    cursor: pointer;
}

@media (max-height: 540px) {
    .nav-element {
        padding: 10px 15px;
        height: 4.5em;
        cursor: pointer;
        width: 5.5em;
    }
}

.language-decorator-wrap {
    display: flex;
    flex-direction: column;
}

.language-decorator-image {
    height: 1.1em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    margin: 0 0.5em;
}

.language-decorator-description {
    font-size: 0.7em;
    text-align: center;
}

.language-decorator-separator {
    background: white;
    height: 2px;
    margin: 0.5em 1.4em;
}

.sidebar-spacer {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em 0;
    padding-top: 0;
}

.language-decorator-slider {
    transition: max-height 0.5s ease-out;
    max-height: 1.5em;
    overflow: hidden;
}

.language-decorator-slider:hover {
    max-height: 4em;
}

@media (hover: none) and (pointer: coarse) {
    .language-decorator-slider {
        max-height: 4em;
    }
}

.language-decorator-row {
    cursor: pointer;
}

.language-decorator-row:hover {
    background: rgba(0, 0, 0, 0.2);
}

.menu-inkdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    z-index: 1;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
}

.menu-inkdrop-closed {
    width: 0 !important;
    opacity: 0 !important;
}

.sidebar-footer {
    text-align: center !important;
    display: grid !important;
    height: 40% !important;
    align-items: flex-end !important;
    cursor: pointer !important;
}

@media (max-height: 540px) {
    .sidebar-footer {
        text-align: center !important;
        display: grid !important;
        height: 45vh !important;
        align-items: flex-end !important;
        cursor: pointer !important;
    }
}