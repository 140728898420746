
.inrow-small-widgets {
    font-size: 0.5em;
    min-width: 25em;
}

.inrow-small-widgets-wrap {
    display: flex;
    width: fit-content;
}

.inrow-small-widgets-wrap-single {
    display: flex;
    width: fit-content;
}

@media (max-width: 1440px) {
    .inrow-small-widgets-wrap {
        display: flex;
        width: fit-content;
    }
}

@media (min-width: 1441px) and (max-width: 1460px) {
    .inrow-small-widgets-wrap {
        display: flex;
        width: 22.6em;
    }
}

@media (min-width: 1461px) and (max-width: 1480px) {
    .inrow-small-widgets-wrap {
        display: flex;
        width: 21.6em;
    }
}

@media (min-width: 1481px) and (max-width: 1500px) {
    .inrow-small-widgets-wrap {
        display: flex;
        width: 20.6em;
    }
}

@media (min-width: 1501px) and (max-width: 1520px) {
    .inrow-small-widgets-wrap {
        display: flex;
        width: 19.8em;
    }
}

#tooltipDashboard .uppertitle-boxed-widget{
    font-size: 1.8em;
}

#tooltipDashboard .biglabel-boxed-widget{
    font-size: 4.2em;
    font-weight: normal;
}


#tooltipDashboard .underlabel-boxed-widget{
    font-size: 2.4em;
    /* font-weight: normal; */
}