
.admin-title {
    font-size: min(6vw,2.2em);
    color: var(--eon-dark-text);
    font-weight: bold;
    margin: 1.3em;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
}

.admin-content {
    padding: 0 1.3em;
}

.admin-content-title {
    font-size: 1em;
    margin: 1em 0;
    color: var(--eon-red);
    font-weight: bold;
}