.apexcharts-line {
    stroke-width: 4;
}

.apexcharts-text tspan{
    font-size: 10px;
}

.spacing-y-axis{
    letter-spacing: 2px;
}

.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center{
    justify-content: left!important;
}