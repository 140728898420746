.main-care-wrap {
    position: fixed;
    right: 8%;
    bottom: min(20vw, 4em);
    background: red;
    z-index: 1000;
    padding: 0.5em;
    border-radius: 1em;
    box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.4);
    opacity: 1;
    animation: fadeIn 3s ease-out;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .main-care-wrap {
        right: 3%;
    }
}

.main-care-wrap-open {
    opacity: 1 !important;
}

.main-care-icon {
    height: 3em;
    width: 3em;
    cursor: pointer;
    position: relative;
    z-index: 1100;
}

.main-care-title {
    text-align: center;
    color: white;
    font-weight: bold;
    padding: 0.2em;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
}

.main-care-inner {
    background: white;
    padding: 1em;
}

.main-care-fake-msg {
    position: relative;
    left: -0.5em;
    top: -0.5em;
    background: var(--eon-red);
    color: white;
    border: 1px solid;
    padding: 0.3em 1em;
    border-radius: 0.5em;
    border-top-left-radius: 0;
}

.main-care-txtarea {
    width: 100%;
    min-height: 20vh;
    border: 2px solid var(--eon-red);
    resize: none;
}

.main-care-button {
    max-width: 5em !important;
    margin: 0 auto !important;
}

.main-care-close-button {
    border: 1px solid white;
    border-radius: 50%;
    width: 1.6em;
    text-align: center;
    position: relative;
    z-index: 1100;
    user-select:none;
}
