
.widjet-map-container {
    height: 47em;
    border: 1px solid rgba(0,0,0,0.5);
}

@media (max-width: 768px) {
    .widjet-map-container{
        margin-bottom: 2em !important;
    }
}

#MappaImpiantiWidjetComponent-TabellaImpiantiWidjetComponent{
    padding-right:unset!important;
}

.popup-margin-fixer {
    margin-top: 1em;
}

.popup-wrapper {
    font-size: min(2vw,1em);
    padding: 0.5em;
    width: min(350px,55vw);
    margin-left: -1em;
}

.upper-popup-title {
    display: flex;
    align-items: center;
}

.plant-name {
    color: var(--eon-dark-text);
    font-size: 1em;
    font-weight: bold;
}

.plant-chevron-label {
    color: var(--eon-blue);
    font-weight: bold;
    margin: 0.4em;
    transform: scaleY(1.5);
}

.plant-details-label {
    font-weight: bold;
    color: var(--eon-red);
    font-size: 1.2em;
    cursor: pointer;
}

