.unknown-widjet {
    padding: 0.5em;
    border: 1px solid red;
    background: rgba(255,0,0,0.3);
    overflow: hidden;
    transition: all 0.5s ease-out;
    max-height: 3em;
    cursor: pointer;
}

.widjet-zone {
    margin: 0.5em;
}

.tabbed-widjet-zone {
    border-top: 0.3em solid var(--eon-red);
}

.widjet-container {
    display: flex;
    align-items: center;
}

.widjet-container-title {
    color: var(--eon-red);
    font-weight: bold;
}

.widjet-tabbed-container {
    display: none;
}

.tabbed-buttons-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: row-reverse;
}

.tabbed-button-for-container-inner{
    display: flex;
    flex-direction:column;
    align-items: center;
}

.tabbed-button-for-container-inner img{
    opacity: 0.7;
}

.tabbed-button-for-container-selected {
    background: var(--eon-red) !important;
}

    .tabbed-button-for-container-selected img {
        opacity: 1 !important;
    }

.tabbed-button-for-container {
   background: #949494;
    width: 2em;
    color: white;
    display: inline-block;
    text-align: center;
    padding: 0.2em 2em;
    user-select: none;
    cursor: pointer;
}


.tabbed-icon-element {
    height: 2em;
    min-width: 2em;
}

.tabbed-title-button-element{

}