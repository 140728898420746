
.admin-detail-box {
    border: 1px solid #c1c1c1;
    padding: 1em;
    border-radius: 0.5em;
}

.admin-detail-box-title {
    color: #848484;
    font-size: 0.8em;
    text-overflow: ellipsis;
    min-width: 5em;
    overflow: hidden;
    white-space: pre;
}
.admin-detail-box-input-wrap {
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1em;
}
.admin-detail-box-input {
    border-color: transparent;
    width: 100%;
    color: #3d3d3d;
}

.email-rows-even {
    text-align: center;
}

.email-rows-odd {
    background: var(--eon-blue-light);
    text-align: center;
}

.email-detail-button {
    width: 5em;
    font-size: min(3vw, 0.7em);
}

.admin-email-button-wrap{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.tooltip-string{
    opacity: 0.8;
    font-size: 0.8em;
}