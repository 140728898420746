
.tableFixHead          { overflow: auto; max-height: 60vh; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }

.colored-table-header-cell {
    background: var(--eon-dark-red);
    color: white;
    padding: 0 0.5em;
    text-align: left;
    white-space: pre;
}

.colored-table-header-after-highlight-cell {
    background: #c6c6c6;
    color: white;
    padding: 0 0.5em;
    text-align: left;
    white-space: pre;
}


.colored-table-header-highlight-cell {
    background: var(--eon-blue);
    color: white;
    padding: 0 0.5em;
    text-align: left;
    white-space: pre;
}

.colored-table-normal-cell {
    text-align: left;
    font-size: 1em;
    padding: 0em 0.5em;
    white-space: pre;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: left;
}

.colored-table-button-cell {
    text-align: center;
    font-size: 1em;
    padding: 0em 0.5em;
    white-space: pre;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.colored-table-highlight-cell {
    color: var(--eon-blue);
    text-align: left;
    font-size: 1em;
    padding: 0em 0.5em;
    white-space: pre;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1em;
}

.colored-table-after-highlight-cell {
    text-align: left;
    font-size: 1em;
    padding: 0em 0.5em;
    white-space: pre;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1em;
}