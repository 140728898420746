
.open-navmenu-button{
    display: none;
}

.eon-logo-navbar{
    display: none;
}

.eon-logo-icon {
    position: fixed;
    height: 50px;
    right: 3em;
    top: 1em;
}

@media (max-width: 991px) {
    .open-navmenu-button{
        display: block;
    }
    .eon-logo-icon{
        display: none;
    }
    .eon-logo-navbar{
        display: block;
    }
}

.top-bar-label{
    color: gray;
    margin: 0 1em;
    cursor: pointer;
}
.top-bar-label:hover{
    color: var(--eon-blue);
}

.navbar {
    border-bottom: none!important;
}

.navbar-quit-icon{
    height: 1em;
    position: relative;
    top:-0.1em;
    margin-right: 0.5em;
    opacity: 0.6;
}

.navbar-logo-icon {
    height: 3.5em;
    position: absolute;
    left: 82%;
    top: 0.6em;
}

@media (max-width: 991px) {
    .navbar-logo-icon {
        height: 3.5em;
        position: absolute;
        left: 82%;
        top: 0.6em;
    }
}
