
.outer-bar-chart-comparison-wrap{
    width: 100%;
}

.bar-chart-comparison-wrap {
    display: flex;
    width: 90%;
    margin: 1em auto;

}
.half-bar {
    height: 1em;
    background: rgba(0,0,0,0.2);
    width: 100px;
}



@media (min-width: 768px) {
    .fixedHeight{
        min-height: 310px;
    }
}
.middle-element-bar {
    width: 1px;
    background: rgba(0,0,0,0.4);
    height: 2em;
    position: relative;
    top: -0.5em;
}

.centered-value {
    position: relative;
    line-height: 6em;
    margin-left: -0.5em;
    color: rgba(0,0,0,0.5);
    font-weight: bold;
}

.delta-label {
    color: var(--eon-blue);
    width: 90%;
    text-align: left;
    margin-bottom: -1em;
    margin-left: 2em;
}


.bar-chart-comparison-triangle {
    position: absolute;
    height: 1em;
    width: 1em;
    margin-top: -0.6em;
    font-size: 20px;
    color: var(--eon-blue);
    z-index: 100;
}