
.admin-title {
    font-size: min(6vw,2.2em);
    color: var(--eon-dark-text);
    font-weight: bold;
    margin: 1.3em;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
}

.admin-content {
    padding: 0 1.3em;
    padding-bottom: 10em;
}

.admin-content-title {
    font-size: 1em;
    margin: 1em 0;
    color: var(--eon-red);
    font-weight: bold;
}

.pick-file-button {
    font-size: 0.8em;
    border: 1px;
    background: blue;
    margin-left: 1em;
    width: 22em;
    position: relative;
    top: 2.7em;
    cursor: pointer;
    opacity: 0;
}