
.economics-small-separator {
    background: var(--eon-red);
    height: 0.1em;
    width: 60%;
    max-width: 200px;
    margin: 1em 0;
}

.economics-lower-big {
    font-size:3.2em;
    color: var(--eon-dark-text);
    font-weight: bold;
}

.economics-lower-mid {
    margin-top: -0.5em;
    font-size:  2.2em;
    color: var(--eon-dark-text);
    font-weight: bold;
}

.economics-lower-small {
    font-size: 1.2em;
    color: var(--eon-dark-text);
}

.economics-lower-tiny {
    font-size: 1em;
    color: var(--eon-dark-text);
}
