.gauge-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gauge-title {
    color: var(--eon-dark-text);
    font-weight: bold;
    margin-bottom: 1em;
    text-align: center;
    font-size: min(3.5vw,1em);
}

.gauge-subtitle {
    color: var(--eon-dark-gray);
    font-size: min(3.5vw,1em);
}

.gauge-percent-label {
    font-size: min(5vw,2em);
    font-weight: bold;
    color: var(--eon-dark-text);
}

#gaugeThermElect > div > svg:nth-child(1) > g:nth-child(2) > text:nth-child(2)  {
   display: none!important;
}

#gaugeThermElect > div > svg:nth-child(1) > g:nth-child(2) > text:nth-child(3)  {
    display: none!important;
 }
/* 
 #gaugeThermElect > div > svg:nth-child(1) > g:nth-child(2) > text:nth-child(5)  {
    display: none!important;
 } */
/*
 #gaugeThermElect > div > svg:nth-child(1) > g:nth-child(2) > text:nth-child(6)  {
    display: none!important;
 } */