.spinner-wrapper{
    width:100%;
    margin: 10vh 0;
}
.spinner-item {
    margin: 0.5em auto;
    width: 5em;
    height: 5em;
    border: 0.3em solid transparent;
    border-radius: 50%;
    border-bottom: 0.3em solid rgb(0, 81, 146);
    animation: spin 0.5s linear infinite;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}