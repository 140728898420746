
.main-panel{
    padding-right: 140px!important;
}
 
@media (max-width: 1024px) {
  .main-panel{
      padding-right: 100px!important;
  }
}


@media (max-width: 850px) {
    .main-panel{
        padding-right: unset!important;
    }

    /* .main-panel > .content{
        overflow: auto!important;
    } */
     
}


.color-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    z-index: 199;
  }
  .color-bar .turquoise {
    position: relative;
    width: 60%;
    height: 100%;
    background-color: #1EA2B1;
  }
  .color-bar .lime-yellow {
    position: relative;
    width: 10%;
    height: 100%;
    background-color: #E3E000;
  }
  .color-bar .red {
    position: relative;
    width: 30%;
    height: 100%;
    background-color: #EB1A0A;
  }

