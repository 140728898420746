.ultime-fatture-wrapper {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    padding: 0.2em;
    margin-right: 0.6em;
    border-radius: 0.5em;
    cursor: pointer;
    font-size: 1em;
}

.extracontent-boxed-widget {
    justify-content: left !important;
}

.ultime-fatture-row-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 18em;
    margin: 2.2em 0;
    min-height: 15em;
}

@media (min-width: 768px) {
    .fixedHeight {
        min-height: 350px;
    }
}


.ultime-fatture-price {
    font-size: min(3vw, 1em);
    color: var(--eon-dark-text);
    font-weight: bold;
    text-align: center;
    max-width: 6em

}

.ultime-fatture-label {
    font-size: min(2.5vw, 1em);
    color: var(--eon-dark-text);
    text-align: center;
    height: 2em;
    max-width: 6em

}

.ultime-fatture-icon {
    height: auto;
    margin: 1.5em 0;
    width: 1000px;
    max-width: min(6vw,90px);
    min-width: 4.5em;
}

@media screen and (min-width: 0px) and (max-width: 1080px) {


    .fixed-prices-tags {
        font-size: 0.8em !important;
        margin-right: 0.7em;
    }
}


    @media screen and (min-width: 0px) and (max-width: 1025px) {



        .ultime-fatture-row-wrap {
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 12em;
            margin: 2.2em 0;
            min-height: 15em;
        }

        .ultime-fatture-icon {
            height: auto;
            margin: 1.5em 0;
            max-width: min(6vw,90px);
        }

        .ultime-fatture-price {
            font-size: 0.85em;
            color: var(--eon-dark-text);
            font-weight: bold;
            text-align: center;
            max-width: 5em
        }

        .ultime-fatture-label {
            font-size: 0.85em;
            color: var(--eon-dark-text);
            text-align: center;
            max-width: 5em
        }
    }


