
.back-button-wrapper {
    padding-top: 1em;
    display: flex;
    align-items: center;
}

.back-button {
    margin: 0 1em;
    height: 2.5em;
    cursor: pointer;
}

.back-button-title {
    font-size: min(6vw,2.2em);
    font-weight: bold;
    color: var(--eon-dark-text)
}