.login-wrap-spacer{
    width:90%;
    transition: width 0.5s ease-in; 
}

@media (max-width: 991px) {
    .login-wrap-spacer{
        width:100%;
        transition: width 0.5s ease-in; 

    }
}

.eon-select-wrap{
    margin-bottom: 0!important;
    margin-top: 1px!important;
}

.dashboard-title {
    font-size: 3.5em;
    color: var(--eon-dark-text);
    font-weight: bold;
}

@media screen and (min-width: 370px) and (max-width: 400px) {
    .dashboard-title {
        font-size: 2.5em;
        color: var(--eon-dark-text);
        font-weight: bold;
    }
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    .dashboard-title {
        font-size: 1.2em;
        color: var(--eon-dark-text);
        font-weight: bold;
    }
}


