@font-face {
  font-family: 'BrixSansRegular';
  src: url('assets/fonts/EON_BrixSans-Regular.ttf');
  src: url("assets/fonts/EON_BrixSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansRegularItalic';
  src: url("assets/fonts/EON_BrixSans-RegularItalic.ttf");
  src: url("assets/fonts/EON_BrixSans-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansMedium';
  src: url("assets/fonts/EON_BrixSans-Medium.ttf");
  src: url("assets/fonts/EON_BrixSans-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansMediumItalic';
  src: url("assets/fonts/EON_BrixSans-MediumItalic.ttf");
  src: url("assets/fonts/EON_BrixSans-MediumItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansBold';
  src: url("assets/fonts/EON_BrixSans-Bold.ttf");
  src: url("assets/fonts/EON_BrixSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansBoldItalic';
  src: url("assets/fonts/EON_BrixSans-BoldItalic.ttf");
  src: url("assets/fonts/EON_BrixSans-BoldItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansBlack';
  src: url("assets/fonts/EON_BrixSans-Black.ttf");
  src: url("assets/fonts/EON_BrixSans-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BrixSansBlackItalic';
  src: url("assets/fonts/EON_BrixSans-BlackItalic.ttf");
  src: url("assets/fonts/EON_BrixSans-BlackItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'BrixSansRegular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

