.spacing-y-axis{
    letter-spacing: 2px;
}

.apexcharts-text tspan{
    font-size: 10px;
}

.apexcharts-legend.position-bottom.apexcharts-align-center, .apexcharts-legend.position-top.apexcharts-align-center{
    justify-content: left!important;
}

.apexcharts-tooltip-goals-group{
    display: none!important;
}