.login-box {
    background: white;
    overflow-y: auto;
    height: 100vh;
    margin-left: 10em;
}

@media (max-width: 799px) {
    .login-box {
        background: white;
        overflow-y: auto;
        height: 85vh;
        margin-left: 0;
    }
}

.login-logo {
    height: 2.2em;
    margin-top: 0.8em;
    cursor: pointer;
}

.login-forest {
    width: 100%;
    padding-right: 5%;
}

@media (max-width: 800px) {
    .login-forest {
        width: 60%;
        padding-right: 5%;
    }
}

.login-block-sep {
    width: 2em;
}
.login-block-wrap {
    min-width: 320px;
    max-width: 45%;
}
.full-inline {
    display: flex;
    width: 100%;
    align-items: center;
}
@media (max-width: 800px) {
    .full-inline {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
}

.login-exp-text {
    margin-top: 0.8em;
    font-size: min(3vw, 0.8em);
    border-left: 0.4em solid var(--eon-red);
    padding-left: 2em;
}

.login-exp-text-form {
    margin-top: 7.35em;
    font-size: min(3vw, 0.8em);
    border-left: 0.4em solid var(--eon-red);
    padding-left: 2em;
}

.login-exp-text-form-register {
    margin-top: 12.35em;
    font-size: min(3vw, 0.8em);
    border-left: 0.4em solid var(--eon-red);
    padding-left: 2em;
}

.inline {
    display: inline-block;
}

.text-section {
    margin-top: 1em;
    padding: 0 2em;
    width: 33em;
}

.login-big-text {
    font-size: min(6vw, 2em);
    font-weight: bold;
}
.small-2 {
    font-size: min(4vw, 0.6em);
    position: relative;
    top: -0.8em;
}

.login-blob-image {
    margin-top: -1em;
    float: right;
    width: 30%;
    max-width: 100px;
    margin-right: 7em;
}
@media (max-width: 800px) {
    .login-blob-image {
        margin-top: -1em;
        float: right;
        width: 30%;
        max-width: 100px;
        margin-right: 10%;
    }
}

.red {
    margin-right: 3px;
    color: var(--eon-red);
}

.login-button {
    text-align: center;
    margin: 1em auto;
    margin-top: 1.2em;
    background: var(--eon-red);
    color: white;
    padding: 0.7em;
    border-radius: 0.5em;
    width: 60%;
    font-weight: bold;
    cursor: pointer;
}
.login-button-disabled {
    text-align: center;
    margin: 1em auto;
    margin-top: 2em;
    background: lightgray;
    color: gray;
    padding: 0.7em;
    border-radius: 0.5em;
    width: 60%;
    font-weight: bold;
    cursor: unset;
}

.login-inner-form-recover-password {
    text-align: center;
    color: var(--eon-red);
    opacity: 0.7;
    cursor: pointer;
}

.login-exp-text-form {
}

.login-inner-form-title {
    position: relative;
    font-weight: bold;
    color: var(--eon-red);
    top: -2em;
    font-size: 1.5em;
}

.login-inner-form {
    padding: 0.5em;
    border: 0.15em solid #c4c4c4;
    border-radius: 0.5em;
    width: 110%;
}

.login-inner-form-input {
    padding: 1em;
    margin: 0 1em;
    width: 90%;
    border: none;
    background: #f6f6f6;
    border-radius: 0.5em;
    color: #383838;
    font-size: 1em;
    padding-right: 3.5em;
}
.login-input-wrap {
    margin: 1.5em 0;
    display: flex;
}
.login-input-icon-check {
    height: 1.2em;
    width: 1.2em;
    position: relative;
    margin-top: 1em;
    margin-left: -3.3em;
    top: 0.2em;
}
.login-input-icon-eye {
    height: 1.8em;
    width: 1.8em;
    position: relative;
    margin-top: 1em;
    margin-left: -3.6em;
}

.expired-banner {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    background: var(--eon-blue);
    width: 100vw;
    padding: 0.3em;
    color: white;
    font-weight: bold;
}

@media (max-width: 800px) {
    .text-section {
        margin-left: -0.5em;
        min-width: 90vw;
    }
}

.register-text-section {
    margin-top: -7em;
    padding: 0 2em;
    width: 33em;
}

@media (max-width: 800px) {
    .register-text-section {
        margin-top: -5em;
        margin-left: -0.5em;
        min-width: 90vw;
    }
}

.error-api-slot {
    text-align: center;
    background: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(255, 0, 0, 1);
    display: none;
}

.agency-code-label {
    position: relative;
    margin-left: 1.2em;
    margin-bottom: -1.5em;
    color: gray;
}

.login-wrap-spacer .main-panel {
    padding-right: unset !important;
}
