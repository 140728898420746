.rebar-boxed-widget {
    background: var(--eon-red);
    height: 0.7em;
    width: 18em;
    max-width: 200px;
}

.biglabel-boxed-widget {
    font-size: 6em;
    color: var(--eon-dark-text);
    font-weight: bold;
}

@media screen and (min-width: 370px) and (max-width: 1024px) {
    .biglabel-boxed-widget {
        font-size: 4em;
        color: var(--eon-dark-text);
        font-weight: bold;
    }
}

.uppertitle-boxed-widget {
    font-size: 3.5em;
    color: var(--eon-red);
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: 0.0em;
    white-space: pre;
}

.underlabel-boxed-widget {
    margin-top: -0.5em;
    font-size: 3em;
    color: var(--eon-dark-text);
    font-weight: bold;
}

.subtitle-boxed-widget {
    font-size: 1.5em;
    color: var(--eon-dark-text);
}

.larger-subtitle-boxed-widget {
    font-size: 2em;
    color: var(--eon-dark-text);
    margin-top: -1em;
}

.image-boxed-widget {
    height: 8em;
}

.image-boxed-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.extracontent-boxed-widget {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;
    flex-wrap: wrap;
}

.button-boxed-widjet-wrapper {
    margin-bottom: 2em;
}

@media (max-width: 770px) {
    .button-boxed-widjet-wrapper {
        justify-content: flex-start !important;
        margin-bottom: 2em;
    }
}