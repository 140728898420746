.table-widget-outerwrap {
    height: 45em;
    overflow: auto;
}

.table-widget-element {
    width: 100%;
    margin-top: 0.1em;
}

.table-widget-header {
    background: var(--eon-red);
    color: white;
    padding: 0.2em 0.5em;
    font-size: min(3vw, 1em);
    text-align: center;
    white-space: pre;
}

.tabella-widget-datacell {
    width: 100%;
    height: 9.5em;
    display: flex;
    align-items: center;   
    overflow: hidden;
}

.tabella-widget-datacell-date {
    width: 100%;
    height: 9.5em;
    display: flex;
    align-items: center;   
    overflow: hidden;
    min-width: 7em;
}

.tabella-widget-datacell-plant {
    width: 100%;
    height: 9.5em;
    display: flex;
    align-items: center;   
    overflow: hidden;
}

.tabella-widget-datacell-highlights{
    width: 100%;
    height: 9.5em;
    display: flex;
    align-items: center;   
    overflow: hidden;
    min-width: 25em;
}

.tabella-widget-datacell-capacity {
    width: 100%;
    height: 9.5em;
    display: flex;
    align-items: center;   
    overflow: hidden;
    min-width: 13em;
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .tabella-widget-datacell-plant {
        width: 100%;
        height: 9.5em;
        display: flex;
        align-items: center;   
        overflow: hidden;
        min-width: 15em;
    }
    .tabella-widget-datacell-capacity{
        width: 100%;
        height: 9.5em;
        display: flex;
        align-items: center;   
        overflow: hidden;
        min-width: 11em;
    }
}



.tabella-widget-title-element {
    font-weight: bold;
    font-size: 1.5em;
}

.tabella-widget-address-element {
    font-size: 1em;
    font-weight: normal;
}

.tabella-widget-date-element {
    font-size: 1.1em;
    width: 100%;
    text-align: center;
}

.tabella-widget-capacity-label {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 1em;
}

.tabella-widget-capacity-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: right;
}

#tabellaImpianti .uppertitle-boxed-widget {
    font-size: 2em;
    color: var(--eon-red);
    font-weight: bold;
    margin-bottom: 0.8em;
}

#tabellaImpianti .larger-subtitle-boxed-widget {
    font-size: 1.4em;
    color: var(--eon-dark-text);
    margin-top: -1em;
}
