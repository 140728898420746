#groupedViewType .button-boxed-widjet {
    margin: 0;
    background: var(--eon-red);
    padding: 0.5em;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    max-width: min(50vw, 300px);
    font-size: min(4vw, 0.8em);
    min-width: 8em;
    user-select: none;
    border: unset
}

#groupedViewType .button-boxed-widjet-excel {
    margin-top: 0;
    background: var(--eon-red);
    padding: 0.5em;
    text-align: center;
    color: white;
    font-weight: bold;
    cursor: pointer;
    max-width: min(50vw, 300px);
    font-size: min(4vw, 0.8em);
    min-width: 8em;
    user-select: none;
    border: unset;
    opacity: 1;
}

@media (max-width: 377px) {
    #groupedViewType .button-boxed-widjet-excel {
        margin-top: 0.5em;
        background: var(--eon-red);
        padding: 0.5em;
        text-align: center;
        color: white;
        font-weight: bold;
        cursor: pointer;
        max-width: min(50vw, 300px);
        font-size: min(4vw, 0.8em);
        min-width: 8em;
        user-select: none;
        border: unset;
        opacity: 1;
    }

}

@media (max-width: 1000px) {
    #groupedViewType .button-boxed-widjet-excel {
        /* margin-top: 0.5em; */
        background: var(--eon-red);
        padding: 0.5em;
        text-align: center;
        color: white;
        font-weight: bold;
        cursor: pointer;
        max-width: min(50vw, 300px);
        font-size: min(4vw, 0.8em);
        min-width: 8em !important;
        user-select: none;
        border: unset;
        opacity: 1;
        height: 2.45em !important;
    }

    .smallico-in-button {
        margin-right: 0 !important;
    }
    
}